.t-hero {
	display: block;
	width: 100%;
  font-size: 8.5vw;
  color: $c-light-brown;
  font-weight: 700;
  line-height: 1;
}

@include media('>=md'){
	.t-hero {
		font-size: 5.8vw;
	}
}

.t-normal {
	font-size: 1.5rem;
}

.t-smaller {
	font-size: 1rem;
}

.t-larger {

}

.t-bold {
	font-weight: 700;
}

.t-upper {
	text-transform: uppercase;
}
