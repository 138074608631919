.bgd-dark-brown {
  background-color: $c-dark-brown;
  color: $c-white;

  .lockup__cross::before,
  .lockup__cross::after {
	background-color: $c-dark-brown;
  }

  .lockup__cross-inner {
	background: $c-brown;
  }

  .lockup__picture::after {
  	background: transparentize( $c-dark-brown, .5);
  }
}

.bgd-brown {
  background-color: $c-brown;
  color: $c-white;

  .lockup__cross::before,
  .lockup__cross::after {
	background-color: $c-brown;
  }

  .lockup__cross-inner {
	background: $c-light-brown;
  }
}

.bgd-light-brown {
  background-color: $c-light-brown;
  color: $c-black;

  .lockup__cross::before,
  .lockup__cross::after {
	background-color: $c-light-brown;
  }

  .lockup__cross-inner {
	background: $c-lighter-brown;
  }
}

.bgd-lighter-brown {
  background-color: $c-lighter-brown;
  color: $c-black;

  .lockup__cross::before,
  .lockup__cross::after {
	background-color: $c-lighter-brown;
  }

  .lockup__cross-inner {
	background: $c-light-brown;
  }
}
