.lockup {
	position: relative;
	width: 100vw;
	height: calc( 100vw * 1.66666666666 );
	display:flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
	padding: 0
}

.lockup__picture {
	width: 100%;
	height: calc(  100vw / 1.5 );
	background-size: cover;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		top: 0;
		bottom: 0;
		left: 0;
	}
}

.lockup__cross {
	position: absolute;
	left: 0;
	top: 50%;
	width: 100vw;
	height: 100vw;
	transform: translateY(-50%);
	z-index: 10;

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: calc( 100vw / 3);
		transform: translateY(-50%);
		background-color: $c-light-brown;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: calc( 100vw / 3);
		transform: translateY(-50%) rotate( 90deg );
		background-color: $c-light-brown;
	}
}

.lockup__cross-inner {
		position: absolute;
		left: 50%;
		top: 50%;
		width: calc( 100vw / 3 );
		height: calc( 100vw / 3 );
		transform: translate(-50%, -50%);
		background: $c-lighter-brown;
		z-index: 30;
}

@include media('>=md') {

    .lockup {
	    width: 40vw;
	    max-height: none;
	    position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		height: auto;
    }

    .row-reverse .lockup {
    	right: auto;
    	left: 0;
    }

    .lockup__picture {
    	width: 40vw;
		height: calc(  50% - (40vw / 6) );
    }

    .lockup__cross {
    	width: 40vw;
		height: 40vw;

		&::before,
		&::after {
			width: 40vw;
			height: calc( 40vw / 3);
		}
    }

    .lockup__cross-inner {
		width: calc( 40vw / 3 );
		height: calc( 40vw / 3 );
    }
}



