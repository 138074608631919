.main__nav {
	display: none;
  padding: 1rem;
  text-align: right;

  &-ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &-li {
    display: inline-block;
  }

  &-link {
    display: inline-block;
    position: relative;
    padding: 1rem;
    color: $c-white;
    font-size: 1.2rem;
    text-decoration: none;

    @include hover {
      &:after {
        position: absolute;
        right: 1rem;
        bottom: 0.8rem;
        left: 1rem;
        height: 1px;
        background-color: $c-white;
        content: '';
      }
    }
  }
}

@include media( '>=md' ){
	.main__nav {
		display: block;
	}
}
