.o-image {
	width: 100%;
	padding-bottom: 100%;
	height: 0;
	position: relative;

	img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
	}

}
