/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

* {
  box-sizing: border-box; }

:root {
  overflow-y: scroll;
  font-size: 12px; }
  @media (min-width: 37.5em) {
    :root {
      font-size: 14.67px; } }
  @media (min-width: 56.25em) {
    :root {
      font-size: 17.33px; } }
  @media (min-width: 75em) {
    :root {
      font-size: 20px; } }

html,
body {
  height: 100%;
  margin: 0;
  background-color: #393633;
  color: #fff;
  font-family: 'rahp', sans-serif;
  line-height: normal; }

body {
  transition: opacity 0.5s ease;
  opacity: 0; }
  .wf-active body,
  .wf-inactive body {
    opacity: 1; }

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal; }

a {
  color: currentColor; }

.row {
  margin-left: -1rem;
  display: flex;
  flex-wrap: wrap; }

.col {
  width: 100%;
  padding-left: 1rem;
  overflow: hidden; }

.row-right {
  justify-content: flex-end; }

.row-left {
  justify-content: flex-start; }

.row-center {
  justify-content: center; }

.row-reverse {
  flex-direction: row-reverse; }

@media (min-width: 56.25em) {
  .row-spread {
    flex-wrap: nowrap; } }

.row-spread > .col {
  width: auto;
  flex-grow: 1; }

@media (min-width: 56.25em) {
  .row-main-sidebar > .col:nth-child(1) {
    width: 60%; }
  .row-main-sidebar > .col:nth-child(2) {
    width: 40%; } }

@media (min-width: 56.25em) {
  .row-50 > .col:nth-child(1) {
    width: 50%; }
  .row-50 > .col:nth-child(2) {
    width: 50%; } }

.t-hero {
  display: block;
  width: 100%;
  font-size: 8.5vw;
  color: #a8a095;
  font-weight: 700;
  line-height: 1; }

@media (min-width: 56.25em) {
  .t-hero {
    font-size: 5.8vw; } }

.t-normal {
  font-size: 1.5rem; }

.t-smaller {
  font-size: 1rem; }

.t-bold {
  font-weight: 700; }

.t-upper {
  text-transform: uppercase; }

.o-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  fill: currentColor; }
  .o-icon svg {
    width: 1em;
    height: 1em; }

.o-image {
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  position: relative; }
  .o-image img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%; }

.o-section {
  min-height: 100%;
  min-height: 100vh;
  padding-top: 150px;
  position: relative; }

@media (min-width: 56.25em) {
  .o-section {
    min-height: calc( 40vw * 1.666666666667); } }

.section__about,
.section__contact,
.section__partners {
  padding: 2rem; }
  @media (min-width: 75em) {
    .section__about,
    .section__contact,
    .section__partners {
      width: 80%; } }
  .section__about-wrap,
  .section__contact-wrap,
  .section__partners-wrap {
    justify-content: flex-end; }

.section__footer {
  padding: 2rem; }

.section__partners {
  padding: 2rem; }

.partner__list {
  padding: 2rem 0; }
  .partner__list-name {
    font-weight: 700;
    margin-bottom: 0; }
  .partner__list-position {
    margin: 0; }

.col__content {
  overflow: visible; }

.main__header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: transform 0.4s cubic-bezier(0.4, 0, 0, 1);
  z-index: 1000; }

.home__hero {
  padding: 2rem;
  position: relative;
  z-index: 100; }
  @media (min-width: 56.25em) {
    .home__hero {
      width: 70%; } }

.lockup {
  position: relative;
  width: 100vw;
  height: calc( 100vw * 1.66666666666);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 0; }

.lockup__picture {
  width: 100%;
  height: calc(  100vw / 1.5);
  background-size: cover;
  position: relative; }
  .lockup__picture::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0; }

.lockup__cross {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100vw;
  height: 100vw;
  transform: translateY(-50%);
  z-index: 10; }
  .lockup__cross:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: calc( 100vw / 3);
    transform: translateY(-50%);
    background-color: #a8a095; }
  .lockup__cross:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: calc( 100vw / 3);
    transform: translateY(-50%) rotate(90deg);
    background-color: #a8a095; }

.lockup__cross-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: calc( 100vw / 3);
  height: calc( 100vw / 3);
  transform: translate(-50%, -50%);
  background: #c7c1b9;
  z-index: 30; }

@media (min-width: 56.25em) {
  .lockup {
    width: 40vw;
    max-height: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: auto; }
  .row-reverse .lockup {
    right: auto;
    left: 0; }
  .lockup__picture {
    width: 40vw;
    height: calc(  50% - (40vw / 6)); }
  .lockup__cross {
    width: 40vw;
    height: 40vw; }
    .lockup__cross::before, .lockup__cross::after {
      width: 40vw;
      height: calc( 40vw / 3); }
  .lockup__cross-inner {
    width: calc( 40vw / 3);
    height: calc( 40vw / 3); } }

.main__logos {
  display: flex;
  padding: 1rem 0;
  color: #fff; }
  .main__logos-col {
    width: 25%;
    padding-left: 1.3rem; }
  .main__logos .o-icon,
  .main__logos svg {
    width: 100px;
    height: 60px;
    color: #fff; }
    @media (min-width: 37.5em) {
      .main__logos .o-icon,
      .main__logos svg {
        width: 180px;
        height: 100px; } }
  .main__logos-logo.o-icon,
  .main__logos-logo svg {
    width: 60px; }
    @media (min-width: 37.5em) {
      .main__logos-logo.o-icon,
      .main__logos-logo svg {
        width: 100px; } }

.main__nav {
  display: none;
  padding: 1rem;
  text-align: right; }
  .main__nav-ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .main__nav-li {
    display: inline-block; }
  .main__nav-link {
    display: inline-block;
    position: relative;
    padding: 1rem;
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none; }
    .main__nav-link:hover:after, .main__nav-link:active:after, .main__nav-link:focus:after {
      position: absolute;
      right: 1rem;
      bottom: 0.8rem;
      left: 1rem;
      height: 1px;
      background-color: #fff;
      content: ''; }

@media (min-width: 56.25em) {
  .main__nav {
    display: block; } }

.bgd-dark-brown {
  background-color: #393633;
  color: #fff; }
  .bgd-dark-brown .lockup__cross::before,
  .bgd-dark-brown .lockup__cross::after {
    background-color: #393633; }
  .bgd-dark-brown .lockup__cross-inner {
    background: #726d65; }
  .bgd-dark-brown .lockup__picture::after {
    background: rgba(57, 54, 51, 0.5); }

.bgd-brown {
  background-color: #726d65;
  color: #fff; }
  .bgd-brown .lockup__cross::before,
  .bgd-brown .lockup__cross::after {
    background-color: #726d65; }
  .bgd-brown .lockup__cross-inner {
    background: #a8a095; }

.bgd-light-brown {
  background-color: #a8a095;
  color: #393633; }
  .bgd-light-brown .lockup__cross::before,
  .bgd-light-brown .lockup__cross::after {
    background-color: #a8a095; }
  .bgd-light-brown .lockup__cross-inner {
    background: #c7c1b9; }

.bgd-lighter-brown {
  background-color: #c7c1b9;
  color: #393633; }
  .bgd-lighter-brown .lockup__cross::before,
  .bgd-lighter-brown .lockup__cross::after {
    background-color: #c7c1b9; }
  .bgd-lighter-brown .lockup__cross-inner {
    background: #a8a095; }

.t-right {
  text-align: right; }
  @media (min-width: 56.25em) {
    .t-right-md {
      text-align: right; } }

.t-left {
  text-align: left; }
  @media (min-width: 56.25em) {
    .t-left-md {
      text-align: left; } }

.t-center {
  text-align: center; }
