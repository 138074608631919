
.main__logos {
  display: flex;
  padding: 1rem 0;
  color: $c-white;

  &-col {
    width: 25%;
    padding-left: 1.3rem;
  }

  .o-icon,
  svg {
    width: 100px;
    height: 60px;
    color: $c-white;

    @include media('>=sm') {
      width: 180px;
      height: 100px;
    }
  }

  &-logo {

    &.o-icon,
    svg {
      width: 60px;

      @include media('>=sm') {
        width: 100px;
      }
    }
  }

}
