.o-section {
  min-height: 100%;
  min-height: 100vh;
  padding-top: 150px;
  position: relative;
}

@include media('>=md') {
	.o-section {
		min-height: calc( 40vw * 1.666666666667 );
	}
}
