.t-right {
  text-align: right;

  &-md {
    @include media('>=md') {
      text-align: right;
    }
  }
}

.t-left {
  text-align: left;

  &-md {
    @include media('>=md') {
      text-align: left;
    }
  }
}

.t-center {
  text-align: center;
}
