$total-columns: 12;

.row {
  @include grid-wrap;
}

.col {
  @include grid-item;
}

.row-right {
  justify-content: flex-end;
}

.row-left {
  justify-content: flex-start;
}

.row-center {
  justify-content: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.row-spread {
  @include media('>=md') {
    flex-wrap: nowrap;
  }
  > .col {
    width: auto;
    flex-grow: 1;
  }
}



.row-main-sidebar {

  @include media('>=md') {
    > .col {

      &:nth-child(1) {
        width: 60%;
      }

      &:nth-child(2) {
        width: 40%;
      }
    }
  }
}


// .row-sidebar-main {

//   @include media('>=md') {
//     > .col {

//       &:nth-child(1) {
//         width: 40%;
//       }

//       &:nth-child(2) {
//         width: 60%;
//       }
//     }
//   }
// }


.row-50 {

  @include media('>=md') {
    > .col {

      &:nth-child(1) {
        width: 50%;
      }

      &:nth-child(2) {
        width: 50%;
      }
    }
  }
}
