
@mixin space-horizontally( $space:1rem ) {
  > *:not(:last-child) {
    margin-right: #{$space};
  }
}

@mixin space-vertically( $space:1rem ) {
  > *:not(:last-child) {
    margin-bottom: #{$space};
  }
}

@mixin hover {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}


$grid-gutter: 1rem !default;
$grid-legacy-support: false !default;

@mixin grid-wrap( $gutter: $grid-gutter ) {
  margin-left: -$gutter;
  display: flex;
  flex-wrap: wrap;

  @if $grid-legacy-support {
    letter-spacing: -0.31em;
  }
}

@mixin grid-item( $proportion: 1, $gutter: $grid-gutter ) {
  width: $proportion * 100%;
  padding-left: $gutter;
  overflow: hidden;

  @if $grid-legacy-support {
    display: inline-block;
    letter-spacing: normal;
    vertical-align: top;
  }
}
