.section__about,
.section__contact,
.section__partners {

  padding: 2rem;

  @include media('>=lg') {
    width: 80%;
  }

  &-wrap {
    justify-content: flex-end;
  }
}


.section__footer {
  padding: 2rem;
}


.section__partners {
  padding: 2rem;
}

.partner__list {
  padding: 2rem 0;

  &-name {
    font-weight: 700;
    margin-bottom: 0;
  }

  &-position {
    margin: 0;
  }
}


.col__content {
	overflow: visible;
}