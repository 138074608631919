$fontPath: '/built/fonts/';

// Colours
$c-dark-brown: #393633;
$c-brown: #726d65;
$c-light-brown: #a8a095;
$c-lighter-brown: #c7c1b9;
$c-black: $c-dark-brown;
$c-white: #fff;

$breakpoints: (
  sm: 37.5em, // 600px
  md: 56.25em, // 900px
  lg: 75em // 1200px
);

$animation-time: 0.2s;
$animation-easing: cubic-bezier(.4,0,0,1);
