* {
  box-sizing: border-box;
}

//Global Styles

:root {
	overflow-y: scroll;
	@include spread(font-size, 12px, 20px);
}

html,
body {
  height: 100%;
  margin: 0;
  background-color: $c-dark-brown;
  color: $c-white;
  font-family: 'rahp', sans-serif;
  line-height: normal;
}

body {
  transition: opacity 0.5s ease;
  opacity: 0;

  .wf-active &,
  .wf-inactive & {
    opacity: 1;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}


a {
  color: currentColor;
}
